import api from './api';

class EnfantDataService {
  getAll() {
    return api.get("/test/enfants/all");
  }

  get(id) {
    return api.get(`/test/enfants/${id}`);
  }

  create(data) {
    return api.post("/test/enfants", data);
  }

  update(id, data) {
    return api.put(`/test/enfant/add/${id}`, data);
  }

  delete(id) {
    return api.delete(`/test/enfants/${id}`);
  }

  deleteAll() {
    return api.delete(`/test/enfants`);
  }

  findByNom(nom) {
    return api.get(`/enfants?nom=${nom}`);
  }
}

export default new EnfantDataService();