<template>
  <div class="list row">
    <div class="col-md-8">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Rechercher par nom"
          v-model="nom"/>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button"
            @click="searchNom"
          >
            Rechercher
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h4>Liste Enfants</h4>
      <ul class="list-group">
        <li class="list-group-item"
          :class="{ active: index == currentIndex }"
          v-for="(enfant, index) in enfants"
          :key="index"
          @click="setActiveEnfant(enfant, index)"
        >
          {{ enfant.nom }}
        </li>
      </ul>

      <button class="m-3 btn btn-sm btn-danger" @click="removeAllEnfants">
        Supprimer Tout
      </button>
    </div>
    <div class="col-md-6">
      <div v-if="currentEnfant">
        <h4>Enfant</h4>
        <div>
          <label><strong>Nom:</strong></label> {{ currentEnfant.nom }}
        </div>
        <div>
          <label><strong>Date naissance:</strong></label> {{ currentEnfant.dateNaissance }}
        </div>
        <div>
          <label><strong>Âge:</strong></label> {{ currentEnfant.age }}
        </div>
        <div>
          <label><strong>Genre:</strong></label> {{ currentEnfant.genre }}
        </div>
        <div>
          <label><strong>Utilisateur:</strong></label> {{ currentEnfant.user.username }}
        </div>

        <router-link :to="'/enfants/' + currentEnfant.id" class="badge badge-warning">Modifier</router-link>
      </div>
      <div v-else>
        <br />
        <p>Veuillez cliquer sur un enfant SVP...</p>
      </div>
    </div>
  </div>
</template>

<script>
import EnfantDataService from "../services/EnfantDataService";

export default {
  name: "enfants-list",
  data() {
    return {
      enfants: [],
      currentEnfant: null,
      currentIndex: -1,
      nom: ""
    };
  },
  methods: {
    retrieveEnfants() {
      EnfantDataService.getAll()
        .then(response => {
          this.enfants = response.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    refreshList() {
      this.retrieveEnfants();
      this.currentEnfant = null;
      this.currentIndex = -1;
    },

    setActiveEnfant(enfant, index) {
      this.currentEnfant = enfant;
      this.currentIndex = enfant ? index : -1;
    },

    removeAllEnfants() {
      EnfantDataService.deleteAll()
        .then(response => {
          console.log(response.data);
          this.refreshList();
        })
        .catch(e => {
          console.log(e);
        });
    },
    
    searchNom() {
      EnfantDataService.findByNom(this.nom)
        .then(response => {
          this.enfants = response.data;
          this.setActiveEnfant(null);
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  mounted() {
    this.retrieveEnfants();
  }
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
</style>